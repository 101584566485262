var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBox', {
    attrs: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('CButton', {
    attrs: {
      "variant-color": "primary",
      "rounded": "1000px",
      "height": ['50px', '60px'],
      "width": "100%",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "is-loading": _vm.isLoadingPrimaryButton
    },
    on: {
      "click": function click($event) {
        return _vm.handlePrimaryButton(_vm.data.text);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.data.text) + " ")]), _c('CButton', {
    attrs: {
      "variant-color": "primary",
      "rounded": "1000px",
      "height": ['50px', '60px'],
      "width": ['50px', '60px'],
      "min-width": ['50px', '60px'],
      "is-loading": _vm.isLoading
    },
    on: {
      "click": _vm.handleOptionButton
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.data.icon,
      "height": "25",
      "width": "100%",
      "fill": "white"
    }
  })], 1), _c('CDrawer', {
    attrs: {
      "is-open": _vm.isOpen,
      "placement": "bottom",
      "on-close": _vm.close
    }
  }, [_c('CDrawerOverlay'), _c('CDrawerContent', [_c('CDrawerCloseButton'), _c('CDrawerHeader', {
    attrs: {
      "color": "#008C81",
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Lainnya "), _c('CBox', {
    on: {
      "click": _vm.close
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-close.svg'),
      "height": "25",
      "width": "100%",
      "fill": "#D32737"
    }
  })], 1)], 1), _c('CDrawerFooter', {
    attrs: {
      "pt": "0px"
    }
  }, [_c('CButton', {
    attrs: {
      "variant-color": "primary",
      "rounded": "1000px",
      "height": ['50px', '60px'],
      "width": "100%",
      "font-size": ['14px', '18px'],
      "font-weight": "500"
    },
    on: {
      "click": function click($event) {
        return _vm.handleLainnyaButton(_vm.data.drawer.textButton);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.data.drawer.textButton) + " ")])], 1)], 1)], 1), _c('ModalConfirmCancelTransaction', {
    attrs: {
      "is-open": _vm.isOpenModalConfirm,
      "is-loading-confirm": _vm.isLoadingDrawer
    },
    on: {
      "handleConfirm": function handleConfirm($event) {
        return _vm.handleDrawerAction(_vm.data.drawer.textButton);
      },
      "handleClose": function handleClose($event) {
        _vm.isOpenModalConfirm = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }