<template>
  <CBox
    display="flex"
    gap="16px"
  >
    <CButton 
      variant-color="primary"
      rounded="1000px"
      :height="['50px','60px']" 
      width="100%" 
      :font-size="['14px', '18px']" 
      font-weight="500" 
      :is-loading="isLoadingPrimaryButton"
      @click="handlePrimaryButton(data.text)"
    >
      {{ data.text }}
    </CButton>
    <CButton 
      variant-color="primary"
      rounded="1000px" 
      :height="['50px','60px']" 
      :width="['50px','60px']" 
      :min-width="['50px','60px']" 
      :is-loading="isLoading"
      @click="handleOptionButton"
    >
      <inline-svg
        :src="data.icon"
        height="25"
        width="100%"
        fill="white"
      />
    </CButton>

    <CDrawer
      :is-open="isOpen"
      placement="bottom"
      :on-close="close"
    >
      <CDrawerOverlay />
      <CDrawerContent>
        <CDrawerCloseButton />
        <CDrawerHeader
          color="#008C81"
          display="flex"
          justify-content="space-between"
        >
          Lainnya
          <CBox @click="close">
            <inline-svg
              :src="require('@/assets/icons/icon-circle-close.svg')"
              height="25"
              width="100%"
              fill="#D32737"
            />
          </CBox>
        </CDrawerHeader>

        <CDrawerFooter pt="0px">
          <CButton
            variant-color="primary"
            rounded="1000px"
            :height="['50px','60px']"
            width="100%"
            :font-size="['14px', '18px']"
            font-weight="500"
            @click="handleLainnyaButton(data.drawer.textButton)"
          >
            {{ data.drawer.textButton }}
          </CButton>
        </CDrawerFooter>
      </CDrawerContent>
    </CDrawer>
    <ModalConfirmCancelTransaction
      :is-open="isOpenModalConfirm"
      :is-loading-confirm="isLoadingDrawer"
      @handleConfirm="handleDrawerAction(data.drawer.textButton)"
      @handleClose="isOpenModalConfirm = false"
    />
  </CBox>
</template>

<script>
import IconOption from '@/assets/icons/icon-option.svg'
import IconDownload from '@/assets/icons/icon-download.svg'
import IconCircleClose from '@/assets/icons/icon-circle-close.svg'
import ModalConfirmCancelTransaction from './modal-confirm-cancel-transaction.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  components: { 
    ModalConfirmCancelTransaction,
  },
  props: {
    transactionId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    invoice: {
      type: String,
      default: '',
    },
    productServiceId: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    flagRoute: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      isOpenModalConfirm: false,
      isLoading: false,
      isLoadingPrimaryButton: false,
      isLoadingDrawer: false,
      IconCircleClose,
    }
  },
  computed: {
    data() {
      switch (this.status) {
        case 'done':
          return {
            text: 'Beri Ulasan',
            icon: IconDownload,
            drawer: {
              textButton: '',
            },
          }
        case 'pending':
          return {
            text: 'Lihat Cara Bayar',
            icon: IconOption,
            drawer: {
              textButton: 'Batalkan Pembelian',
            },
          }
        default:
          return {
            text: 'Beli Kembali',
            icon: IconOption,
            drawer: {
              textButton: 'Bantuan',
            },
          }
      }
    },
  },
  methods: {
    ...mapActions({
      downloadInvoice: 'checkout/downloadInvoice',
      midtransCancel: 'checkout/midtransCancel',
      onUpdateCart: 'cart/onUpdateCart',
      list: 'cart/list',
      getProductServiceId: 'general/getProductServiceId',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    async handlePrimaryButton(type) {
      this.isLoadingPrimaryButton = true
      if (type === 'Beli Kembali') {
        try {
          let params = {
            productServiceId: this.productServiceId,
            extend: this.quantity,
          }
          await this.onUpdateCart(params)
          await this.list()

          const productService = await this.getProductServiceId(this.productServiceId)
          this.setSelectedCart({
            quantity: this.quantity,
            productService: {
              ...productService.data || {},
            },
            _isBuyNow: true,
          })
          this.$router.push({ name: 'client.cart.check-order' })
        } catch (e) {
          console.error(e)
        }

      }
      if (this.status == 'pending') {
        if (this.flagRoute == 'landingpage') {
          this.$router.push({
            name: 'client.buy.payment',
            query: { transaction_id: this.transactionId },
          })
        } else {
          this.$router.push({
            name: 'client.checkout.detail',
            params: { id: this.transactionId },
          })
        }
      }

      this.isLoadingPrimaryButton = false
    },
    handleOptionButton() {
      // download invoice
      if (this.status === 'done') {
        this.isLoading = true
        this.downloadInvoice(this.transactionId)
          .then((res) => {
            const blob = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = blob
            link.setAttribute('download', `INVOICE-${this.invoice}.pdf`)
            document.body.appendChild(link)
            link.click()
          })
          .catch((err) => {
            this.$toast({
              status: 'error',
              title: 'Error',
              description: err?.data.message,
              duration: 3000,
            })
          })
          .finally(() => {
            this.isLoading = false
          })
        return
      }
      this.isOpen = true
    },
    handleLainnyaButton(type) {
      if (type?.toLowerCase() === 'bantuan') {
        this.$router.push({ name: 'under-maintenance' })
        return
      }
      this.isOpenModalConfirm = true
    },
    close() {
      this.isOpen = false
    },
    handleDrawerAction(type) {
      if (type === 'Batalkan Pembelian') {
        this.isLoadingDrawer = true
        this.midtransCancel({
          transactionId: this.transactionId,
        })
          .then(() => {
            this.$toast({
              status: 'success',
              title: 'Success',
              // description: 'res?.data.message',
              duration: 1500,
            })
            window.location.reload()
          })
          .catch(() => {
            this.$toast({
              status: 'error',
              title: 'Error',
              // description: 'err?.data.message,
              duration: 1500,
            })
          }).finally(() => {
            this.isLoadingDrawer = false
          })
        return
      }
    },
  },
}
</script>